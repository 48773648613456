<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-form-item
          label="标题："
          prop="name"
          :rules="[{ required: true, message: '请输入标题' }]"
        >
          <el-input
            placeholder="请输入标题"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="标签类型："
          prop="type"
          :rules="[{ required: true, message: '请选择是否标签类型' }]"
        >
          <el-select v-model="formLabelAlign.type" placeholder="请选择标签类型">
            <el-option
              v-for="item in type_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item
          label="是否启用："
          prop="is_enable"
          :rules="[{ required: true, message: '请选择是否启用' }]"
        >
          <el-radio
            v-for="item in enable_list"
            v-model="formLabelAlign.is_enable"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-radio
          >
        </el-form-item> -->
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { tagssaveInfo, tagssave } from "../../assets/request/api";

export default {
  name: "student_redacy",

  props: {
    dialog_show: {
      type: Boolean,
    },
  },
  data() {
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      enable_list: [],
      type_list: [],
      formLabelAlign: {
        name: "",
        is_enable: "",
        type: "",
      },
    };
  },
  methods: {
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      tagssaveInfo(froms).then((res) => {
        let { info, enable_list ,type_list} = res.data;
        this.enable_list = enable_list;
        this.type_list = type_list;
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let { name, is_enable ,type} = info;
          formLabelAlign.name = name;
          formLabelAlign.is_enable = is_enable;
          formLabelAlign.type = type;
          
        }
      });
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign, id } = this;
          if (id) formLabelAlign.id = id;
          tagssave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.$emit("update:refbool", false);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
</style>